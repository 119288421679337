.share-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5d9;
    width: 90%; 
    padding: 10px;
    margin-bottom: 2vh;
}

.share-title {
    margin: 0;
    font-size: 1.5rem; /* Usamos unidades relativas */
  }
  
.share-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
}
  
.share-icon {
    width: 40px;
    height: 40px;
    color: white;
    background: linear-gradient(45deg, #ff5e62, #ff9966);
    padding: 5px;
}