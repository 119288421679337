#icon-menu{
    color:white;
    font-size: 35px;
}

.MuiMenu-list{
    color: white;
    background-color: #dd6559;
}

.menu-link{
    font-size: 18px;
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
}

#long-button{
    margin: 0 !important;
    padding: 0 !important;
}

.menu-icon{
    width: 50px;
    margin-right: 5px;
}