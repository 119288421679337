.results-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    width: 60vw;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.anime-data {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.anime-data img {
    width: 0;
    height: auto;
    flex: 1;
    object-fit: cover;
}


.anime-info {
    flex: 2;
    padding: 20px;
    background-color: #f5f5f5d9;
    border-radius: 8px;
}


.anime-text {
    margin: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size:1rem;
}

.anime-title {
    display:flex;
    align-items: center;
}

.video-wrapper {
    margin-top: 20px;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.anime-result {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 2vw;
    background-color: #f5f5f5d9;
    width: 100%;
    height: 5vw;
    overflow: visible;
    position: relative;
}
  
.result-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    background-color: #fff7d8;
    width: 7vw;
    height: 7vw;
    margin: -1vw;
}

.result-title {
    margin: 0;
    font-size: 1vw;
}

.result-text {
    margin: 0;
    font-size: 2vw;
}
  
.results-last-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 2vh;
}
  
.redirect-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
}
  
.buttons-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
  
.redirect-button {
    flex-grow: 1;
    margin-bottom: 5vh;
    font-size: 20px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
    display: flex;
    justify-content: left;
    align-items: center;
}
  
.button-icon {
    width: 60px;
}

.buttons-row .MuiBadge-badge{
    position: absolute;
    right: 15px;
    top: -7px;
}

/* MEDIA QUERY */

@media (max-width: 768px) {
    .results-container {
      width: 95%;
    }
  
    .anime-data {
      flex-direction: column;
    }
  
    .anime-data img {
      display: none;
      width: 0;
      height: 0;
    }
  
    .anime-info {
      flex: none;
      width: 100%;
    }
  
    .anime-info {
      width: 100%; 
      box-sizing: border-box; 
    }
  
    .video-wrapper,
    video {
      width: 100%; 
      max-width: 100%; 
    }
}
  
@media (max-width: 768px) {
    .result-circle {
        width: 12vw;
        height: 12vw;
    }
  
    .result-title {
    font-size: 2vw;
    }

    .result-text {
    font-size: 3.5vw;
    }
}
  
  
@media (min-width: 600px) {
    .results-last-row {
      margin-top: 5vh;
    }
}
  
@media (min-width: 1200px) {
    .results-last-row {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    .buttons-row {
        flex-direction: row;
        gap: 20px;    
        margin-bottom: 3vh;
    }
    .redirect-button {
        width: 15vw; 
        margin: 0 1vw; 
    }
}
  