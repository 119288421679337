.video-container{
    width: 100%;
    height: fit-content;
    position: relative;
    overflow: hidden;
    background-color: rgb(0,0,0);
}

.player-wapper1{
    position: absolute;
    top: 0;
}

.player-wrapper2{
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-controls{
    display: flex;
    align-items: center;
    height:40px;
    width: 100%;
    background-color: rgb(0,0,0,0.5);
    z-index: 120;
    position: absolute;
    bottom: 0;
}

.control-icon{
    color: white;
}

.overlay-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.795);
    z-index: 1;  /* Asegúrate de que el texto esté sobre el video */
}

@media(max-width: 300px){
    .overlay-text{
        font-size: 1rem;
    }
}
  