.game-container{
    width: 90vw; /* Cambiar esto para hacerlo responsive */
    z-index: 1;
    margin-top: 80px;
}

@media (min-width: 600px) {
    .game-container{
        width: 50vw;
    }
}

@media (min-width: 1400px) {
    .game-container{
        width: 30vw;
    }
}

.confetti1{
    position: absolute;
    top: 0;
    left: 0;
}

.confetti2{
    position: absolute;
    top: 0;
    right: 0;
}

.guess-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1vw;
}

.search-buttons{
    display: flex;
    align-items: center;
    flex:1;
    justify-content: right;
    gap: 1vw;
}

@media (max-width: 420px) {
    .guess-container {
        flex-direction: column-reverse;
        align-items: initial;
    }
    .search-buttons{
        margin-bottom: 10px;
        justify-content: left;
    }
}

.attempts-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top:10px;
}

.attempt{
    background-color: rgb(255, 255, 255, 0.8);
    height: 2.5rem;
    padding: 0 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.attempt-text{
    margin-left: 10px;
    font-size:16px;
}

.lives-row{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    margin: 12px 0;
}

.switch-text{
    font-weight: bold;
    margin: 0;
    color: #DE6262;
    text-shadow: 0 1px 0 rgba(0,0,0,.4);
}

.switch-container{
    display:flex;
    align-items: center;
    font-size:1vh;
    gap:1vw;
    background-color: rgb(255, 255, 255, 0.8);
    padding: 5px 7px;   
    border-radius: 5px;
}

@media (max-width: 300px) {
    .switch-container{
        font-size: 0.5vh;
    }
}

.tgl {
    display: none;
    
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    & + .tgl-btn {
      box-sizing: border-box;
      &::selection {
        background-color: none;
      }
    }
    
    + .tgl-btn {
      outline: 0;
      display: block;
      width: 4em;
      height: 2em;
      position: relative;
      cursor: pointer;
      user-select: none;
      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }
      
      &:after {
        left: 0;
      }
      
      &:before {
        display: none;
      }
    }
    
    &:checked + .tgl-btn:after {
      left: 50%;
    }
  }
  


.tgl-skewed {
+ .tgl-btn {
    overflow: hidden;
    transform: skew(-10deg);
    backface-visibility: hidden;
    transition: all .2s ease;
    font-family: sans-serif;
    background: #FFB88C;
    &:after,
    &:before {
    transform: skew(10deg);
    display: inline-block;
    transition: all .2s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 2em;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0,0,0,.4);
    }
    
    &:after {
    left: 100%;
    content: attr(data-tg-on);
    }
    
    &:before {
    left: 0;
    content: attr(data-tg-off);
    }
    
    &:active {
    background: #FFB88C;
    &:before {
        left: -10%;
    }
    }
}

&:checked + .tgl-btn {
    background: #DE6262;
    &:before {
    left: -100%;
    }

    &:after {
    left: 0;
    }

    &:active:after {
    left: 10%;
    }
}
}