.input-wrapper {
    width: auto;
    height: 2.5rem;
    border: none;
    border-radius: 10px;
    padding: 0 5%;
    box-shadow: 0px 0px 8px #ddd;
    background-color: white;
    display: flex;
    align-items: center;
}

input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: 1rem;
    width: 100%;
    margin-left: 5px;
}

input:focus {
    outline: none;
}

.results-list {
    width: 70vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    height: min(30vh,auto);
}

@media (min-width: 600px) {
    .results-list {
        width: 100%;
    }
}

.search-result {
    padding: 10px 20px;
}
  
.search-result:hover {
    background-color: #efefef;
}

.search-bar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position:relative;
}

.search-results-container {
    position: relative;
    width:100%;
}

@media (min-width: 600px) {
    .search-results-container {
        width: 65%;
    }
}