.main-page{
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.opening{
    background-image: url(../assets/background/temple_background.png);
}

.hardcore-opening{
    background-image: url(../assets/background/dark_drake_background.png);
}

.ending{
    background-image: url(../assets/background/matsuri_background.png);
}

.hardcore-ending{
    background-image: url(../assets/background/white_drake_background.png);
}