.navbar{
    height: 50px;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 100;
    justify-content: space-between;
    margin: 0 2.5vw;
    display: grid;     
    grid-template-columns: 1fr auto 1fr;     
    grid-template-rows: 50px;    
    grid-column-gap: 0px;     
    grid-row-gap: 0px;
}

.navbar-logo{
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-family: Kaushan Script;
    color: #427D91;
    width:fit-content !important;
    padding-left: 2.5vw;
    /*color: #d8475a;*/
}

.logo{
    height: 40px;
    width: auto;
    margin-right: min(2.5vw,10px);
}

.navbar-title{
    font-size: min(3vw,20px);
    font-family: Kaushan Script;
    color: white;
    flex-grow: 2;
    display:flex;
    align-items: center;
    justify-content: center;
    text-shadow: 2px 2px 4px #000000;
}

.navbar-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  color: #333;
  padding-right:1.8vw;
}

.navbar-clock{
    color: #fbede4;
    margin-right:20px;
}

.language-selector {
    display: flex;
    align-items: center;
    gap: 10px;
}

.language-selector img {
    width: 30px;
    margin-left:20px;
    cursor: pointer;
}

@media (max-width: 820px) {
    .logo-title{
        display: none;
    }

    .navbar-clock{
        display: none;
    }
}